import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import Button from '@uz/unitz-components-web/Button';
import ModalProvider from '@uz/unitz-providers/ModalProvider';
import CourseRoomManageSubstitutesModal from '@uz/unitz-tool-components/CourseLessonComponent/components/CourseRoomManageSubstitutesModal';
import CourseRoomRescheduleModal from '@uz/unitz-tool-components/CourseLessonComponent/components/CourseRoomRescheduleModal';
import CourseRoomCancelModal from '@uz/unitz-tool-components/CourseLessonComponent/components/CourseRoomCancelModal';
import { Dropdown, Menu } from 'antd';
import { ACL } from '@vl/mod-utils/ACL';
import AsyncRender from '@uz/unitz-components-web/AsyncRender';

export const CourseRoomActionMenu = () => {
  return (
    <DIV className="listItem">
      <ModalProvider>
        <AsyncRender
          render={async () =>
            (await ACL.room.isAdmin(ctx.get('@item.room.id'))) && (
              <Dropdown
                destroyPopupOnHide
                trigger={['click']}
                placement="bottomCenter"
                overlay={() => {
                  return (
                    <Menu className="overflow-hidden shadow-md" style={{ borderRadius: 8, padding: 0 }}>
                      {/* {ctx.apply('courseRoomModel.can', 'cancel') && (
                  <Menu.Item
                    className="text-base border-b border-border"
                    key="cancel"
                    onClick={() => {
                      ctx.apply('modalModel.show', () => {
                        return <CourseRoomCancelModal />;
                      });
                    }}
                  >
                    <div className="py-2">{ctx.apply('i18n.t', 'ZoomToolCalendar.roomActions.cancel')}</div>
                  </Menu.Item>
                )} */}

                      <AsyncRender
                        render={async () =>
                          (await ACL.room.isAdmin(ctx.get('@item.room.id'))) && (
                            <Menu.Item
                              className="text-base border-b border-border"
                              key="cancel"
                              onClick={() => {
                                ctx.apply('modalModel.show', () => {
                                  return <CourseRoomCancelModal />;
                                });
                              }}
                            >
                              <div className="py-2">{ctx.apply('i18n.t', 'ZoomToolCalendar.roomActions.cancel')}</div>
                            </Menu.Item>
                          )
                        }
                      />

                      <AsyncRender
                        render={async () =>
                          (await ACL.room.isAdmin(ctx.get('@item.room.id'))) && (
                            <Menu.Item
                              className="text-base border-b border-border"
                              key="reschedule"
                              onClick={() => {
                                ctx.apply('modalModel.show', () => {
                                  return <CourseRoomRescheduleModal />;
                                });
                              }}
                            >
                              <div className="py-2">
                                {ctx.apply('i18n.t', 'ZoomToolCalendar.roomActions.reschedule')}
                              </div>
                            </Menu.Item>
                          )
                        }
                      />

                      <AsyncRender
                        render={async () =>
                          (await ACL.room.isAdmin(ctx.get('@item.room.id'))) && (
                            <Menu.Item
                              className="text-base border-b border-border"
                              key="substitutes"
                              onClick={() => {
                                ctx.apply('modalModel.show', () => {
                                  return <CourseRoomManageSubstitutesModal />;
                                });
                              }}
                            >
                              <div className="py-2">
                                {ctx.apply('i18n.t', 'ZoomToolCalendar.roomActions.manageSubstitutes')}
                              </div>
                            </Menu.Item>
                          )
                        }
                      />

                      {/* {ctx.apply('courseRoomModel.can', 'substitutes') && (
                  <Menu.Item
                    className="text-base border-b border-border"
                    key="substitutes"
                    onClick={() => {
                      ctx.apply('modalModel.show', () => {
                        return <CourseRoomManageSubstitutesModal />;
                      });
                    }}
                  >
                    <div className="py-2">{ctx.apply('i18n.t', 'ZoomToolCalendar.roomActions.manageSubstitutes')}</div>
                  </Menu.Item>
                )} */}
                      {/* {ctx.apply('courseRoomModel.can', 'refund') && (
                  <Menu.Item
                    key="refund"
                    onClick={() => {
                      ctx.apply('modalModel.show', () => {
                        return <CourseRoomRefundModal />;
                      });
                    }}
                  >
                    {ctx.apply('i18n.t', 'CourseRoom.menus.refund.title')}
                  </Menu.Item>
                )}
                {ctx.apply('courseRoomModel.can', 'message') && (
                  <Menu.Item key="message">
                    <a href={ctx.apply('courseRoomModel.linkTo')} target="_blank">
                      {ctx.apply('i18n.t', 'CourseRoom.menus.message.title')}
                    </a>
                  </Menu.Item>
                )}
                {ctx.apply('courseRoomModel.can', 'dismiss') && (
                  <Menu.Item
                    key="dismiss"
                    onClick={() => {
                      ctx.apply('modalModel.show', () => {
                        return <CourseRoomDismissModal />;
                      });
                    }}
                  >
                    {ctx.apply('i18n.t', 'CourseRoom.menus.dismiss.title')}
                  </Menu.Item>
                )}
                {ctx.apply('courseRoomModel.can', 'unfollow') && (
                  <Menu.Item
                    key="unfollow"
                    onClick={() => {
                      ctx.apply('modalModel.show', () => {
                        return <CourseRoomUnfollowModal />;
                      });
                    }}
                  >
                    {ctx.apply('i18n.t', 'CourseRoom.menus.unfollow.title')}
                  </Menu.Item>
                )}
                 */}
                    </Menu>
                  );
                }}
              >
                <Button
                  type="link"
                  name="ink"
                  icon={gstyles.icons({ name: 'more', size: 24, fill: gstyles.colors.sub })}
                  area-label="action"
                  disabled={false}
                />
              </Dropdown>
            )
          }
        />
      </ModalProvider>
    </DIV>
  );
};

export default CourseRoomActionMenu;
