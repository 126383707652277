import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import ZoomToolCalendar from '@uz/unitz-tool-components/ZoomToolCalendar';
import Layout from '@uz/unitz-layout-web/LayoutFullPageContent';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Form } from 'formik-antd';
import ToolCalendarFilter from '@uz/unitz-tool-components/ToolCalendarFilter';
import styled from 'styled-components';
import 'moment/locale/de';
import moment from 'moment';
import i18n from 'i18n-js';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
};

const CustomSpacing = styled.div`
  .content-header {
    & > div {
      padding: 0;
    }
  }
`;

const Index = () => {
  moment.locale(i18n.locale);
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="px-4 pt-4 mb-2">
          <FormProvider form={ctx.get('form')}>
            <Form {...layout} className="col-filter-form">
              <ToolCalendarFilter />
            </Form>
          </FormProvider>
        </div>
        <CustomSpacing>
          <Layout className="px-4">
            <DIV>
              <Layout.POS name="content-body">
                <ZoomToolCalendar />
              </Layout.POS>
            </DIV>
          </Layout>
        </CustomSpacing>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
