import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import moment from 'moment';
import { momentLocalizer } from 'react-big-calendar';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import Button from '@uz/unitz-components-web/Button';
import i18n from 'i18n-js';
import ToolCourseRoomLectures from '@uz/unitz-tool-components/ToolCourseRoomLectures';
import { Row, Col } from 'antd';
import ZoomToolCourseRoomQuickAction from '@uz/unitz-tool-components/ZoomToolCourseRoomQuickAction';
import ZoomToolPrepareLessonAction from '@uz/unitz-tool-components/ZoomToolPrepareLessonAction';
import ToolCourseRoomMenu from '@uz/unitz-tool-components/ToolCourseRoomMenu';

import { TIME_HOUR_MINUTE_ML_FORMAT, START_DATE_ML_FORMAT } from '@vl/mod-utils/datetime';
import { Link } from '@uz/unitz-components-web/Link';

import { ACL } from '@vl/mod-utils/ACL';
import AsyncRender from '@uz/unitz-components-web/AsyncRender';

const localizer = momentLocalizer(moment);

export const EventDetailsCard = ({ event, onCloseHandler }) => {
  return (
    <DIV forceCtx>
      {ctx.debug(() => {
        ctx.set('@item', event);
      })}
      <div className="rounded-lg" style={{ width: 400, zIndex: 1000 }}>
        <div className="flex items-center justify-between my-2">
          <div className="text-xs text-main font-semibold">
            <Link
              className="text-main"
              to={ctx.apply('routeStore.toUrl', 'toolAccountCourseDetail', _.get(event, 'course'))}
            >
              <div>
                {ctx.apply('i18n.t', 'ZoomToolCalendar.course')}: {_.get(event, 'course.name', '')}
              </div>
              <div>
                {ctx.apply('i18n.t', 'ZoomToolCalendar.lesson')}: {_.get(event, 'room.name', '')}
              </div>
            </Link>
          </div>
          <Button
            className="ml-4"
            type="link"
            name="ink"
            onClick={onCloseHandler}
            icon={gstyles.icons({ name: 'close', size: 24, fill: gstyles.colors.sub })}
            disabled={false}
          />
        </div>
        <div className="text-xs font-normal text-main">
          <span className="capitalize">
            {`${localizer.format(event.start, 'dddd')},  ${localizer.format(
              event.start,
              _.get(START_DATE_ML_FORMAT, i18n.locale, START_DATE_ML_FORMAT.de)
            )}`}
          </span>
          <span className="ml-2">
            {_.join(
              [
                localizer.format(
                  event.start,
                  _.get(TIME_HOUR_MINUTE_ML_FORMAT, i18n.locale, TIME_HOUR_MINUTE_ML_FORMAT.de)
                ),
                localizer.format(
                  event.end,
                  _.get(TIME_HOUR_MINUTE_ML_FORMAT, i18n.locale, TIME_HOUR_MINUTE_ML_FORMAT.de)
                ),
              ],
              ' - '
            )}
          </span>
        </div>
        <div className="mt-2">
          <ToolCourseRoomLectures />
        </div>
        <div className="flex justify-between mt-5">
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <ZoomToolCourseRoomQuickAction />
            </Col>
            <AsyncRender
              render={async () =>
                (await ACL.room.isAdmin(_.get(event, 'room.id', ''))) && (
                  <Col span={12}>
                    <ZoomToolPrepareLessonAction size="small" />
                  </Col>
                )
              }
            />
          </Row>
          <div>
            <ToolCourseRoomMenu />
          </div>
        </div>
      </div>
    </DIV>
  );
};

export default EventDetailsCard;
