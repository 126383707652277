import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { FormItem, Select } from 'formik-antd';
import { Row, Col } from 'antd';
import Button from '@uz/unitz-components-web/Button';
import ToolSearchInput from '@uz/unitz-tool-components/ToolSearchInput';
import CustomInput from '@uz/unitz-components-web/CustomInput';
import gstyles from '@vl/gstyles';

const Index = () => {
  return (
    <DIV className="component">
      <div className="px-4 pt-4 bg-white500 rounded-lg">
        <Row justify="space-between" gutter={[16, 16]}>
          <Col span={16}>
            <Row gutter={[16, 0]}>
              <Col span={8}>
                <FormItem
                  name="viewBy"
                  label={
                    <div className="text-sub font-semibold text-sm">
                      {ctx.apply('i18n.t', 'ZoomToolCalendar.Form.viewBy')}
                    </div>
                  }
                >
                  <CustomInput>
                    <Select
                      size="large"
                      name="viewBy"
                      suffixIcon={gstyles.icons({
                        name: 'arrow-down3',
                        size: 24,
                        fill: gstyles.colors.sub,
                        className: '-mt-2 -ml-2',
                      })}
                    >
                      {_.map(ctx.get('form.selectOptions.viewBy'), (item, index) => (
                        <Select.Option
                          style={{ padding: 16 }}
                          className="border-b border-border text-base"
                          value={item.value}
                          key={index}
                        >
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </CustomInput>
                </FormItem>
              </Col>
              <Col span={8}>
                <ToolSearchInput
                  name="search"
                  label={ctx.apply('i18n.t', 'ZoomToolCalendar.Form.search')}
                  onClick={ctx.get('form.handleSubmit')}
                />
              </Col>
              <Col span={8}>
                <FormItem
                  name="status"
                  label={
                    <div className="text-sub font-semibold text-sm">
                      {ctx.apply('i18n.t', 'ZoomToolCalendar.Form.status')}
                    </div>
                  }
                >
                  <CustomInput>
                    <Select
                      size="large"
                      name="status"
                      suffixIcon={gstyles.icons({
                        name: 'arrow-down3',
                        size: 24,
                        fill: gstyles.colors.sub,
                        className: '-mt-2 -ml-2',
                      })}
                    >
                      {_.map(ctx.get('form.selectOptions.status'), (item, index) => (
                        <Select.Option
                          style={{ padding: 16 }}
                          className="border-b border-border text-base"
                          value={item.value}
                          key={index}
                        >
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </CustomInput>
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name="program"
                  label={
                    <div className="text-sub font-semibold text-sm">
                      {ctx.apply('i18n.t', 'ZoomToolCalendar.Form.program')}
                    </div>
                  }
                >
                  <CustomInput>
                    <Select
                      showSearch
                      size="large"
                      name="program"
                      notFoundContent={<div className="text-center py-6">{ctx.apply('i18n.t', 'noData')}</div>}
                      suffixIcon={gstyles.icons({
                        name: 'arrow-down3',
                        size: 24,
                        fill: gstyles.colors.sub,
                        className: '-mt-2 -ml-2',
                      })}
                    >
                      {_.map(ctx.get('programs'), (item, index) => (
                        <Select.Option
                          style={{ padding: 16 }}
                          className="border-b border-border text-base"
                          value={item.id}
                          key={index}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </CustomInput>
                </FormItem>
              </Col>
            </Row>
          </Col>
          <Col style={{ alignSelf: 'flex-start' }} className="mt-8 button-group" span={8}>
            <Row gutter={12} justify="end">
              <Col>
                <Button style={{ borderColor: gstyles.colors.border }} onClick={() => ctx.apply('form.resetForm')}>
                  {ctx.apply('i18n.t', 'ZoomToolCalendar.Action.reset')}
                </Button>
              </Col>

              <Col>
                <Button disabled={!ctx.get('form.dirty')} onClick={ctx.get('form.handleSubmit')} type="primary">
                  {ctx.apply('i18n.t', 'ZoomToolCalendar.Action.save')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </DIV>
  );
};

export default displayName(Index);
