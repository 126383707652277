import React from 'react';

import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';

import _ from 'lodash';
import fbFnsClient from '@vl/mod-clients/fibGatsbyFns';
import useRoute from '@vl/hooks/useGbRouteDe';
import moment from 'moment';
import Avatar from '@uz/unitz-components-web/Avatar';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import MemberFormatter from '@uz/unitz-models/B2BMemberModel/formatter';
import gstyles from '@vl/gstyles';
import RenderError from './RenderError';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook((ctx) => {
              const route = useRoute();
              const [isModalVisible, setIsModalVisible] = React.useState(true);
              const userId = ctx.apply('authModel.getUserId');
              const item = ctx.get('@item');
              const room = _.get(item, 'room');

              const form = useFormik({
                enableReinitialize: false,
                initialValues: {
                  user_id: userId,
                  room_id: _.get(room, 'id'),
                  lesson_name: _.get(room, 'name', ''),
                  course_name: _.get(room, 'course.name', ''),
                  start_at: _.get(item, 'start_at'),
                  applyFor: '',
                  session_duration: moment(_.get(room, 'end_at')).diff(moment(_.get(room, 'start_at')), 'seconds'),
                  update_same_rooms: false,
                  reason: '',
                  teachers: _.map(_.get(room, 'course_room_teachers', []), (teacher) => {
                    return {
                      ..._.omit(teacher, ['member', 'teacher_type']),
                      id: _.get(teacher, 'member.id', ''),
                      role: _.get(teacher, 'teacher_type', ''),
                      member_profile: _.get(teacher, 'member.member_profile', {}),
                    };
                  }),
                },
                onSubmit: async (values, actions) => {
                  const errors = {};
                  const params = {
                    ..._.omit(values, ['old_start_at', 'sessions']),
                  };
                  try {
                    // const data = await fbFnsClient.getClient().post('course-rescheduleRoom', params);
                    // if (_.get(data, 'errors')) {
                    //   errors.sessions = RenderError(data.errors);
                    //   return form.setErrors({ sessions: RenderError(data.errors) });
                    // }
                    // if (_.get(data, 'error')) {
                    //   return actions.setStatus({
                    //     error: data,
                    //   });
                    // }
                    // form.handleCancel();
                  } catch (error) {
                    actions.setStatus({
                      error,
                    });
                  }
                },
              });

              _.assign(form, {
                isModalVisible,
                canSubmit: !_.get(form, 'isSubmitting') && _.get(form, 'isValid') && _.get(form, 'dirty'),
                showModal: () => {
                  setIsModalVisible(true);
                },
                handleCancel: () => {
                  setIsModalVisible(false);
                },

                handleChangeCheckbox: (value) => {
                  const { checked, name } = value.event.target;

                  const field = _.get(value, 'field', '');
                  const rec = _.get(value, 'value', '');

                  if (checked) {
                    form.setFieldValue(field, [..._.get(form.values, field), { ...rec }]);
                  } else {
                    form.setFieldValue(
                      field,
                      _.filter(_.get(form, `values.${field}`), (v) => {
                        return _.get(v, 'id') !== name;
                      })
                    );
                  }
                },

                selectOptions: {
                  sizeEffect: (() => {
                    return [
                      {
                        id: 'this_lesson',
                        value: true,
                        text: 'This lesson',
                      },
                      {
                        id: 'this_and_following_lessons',
                        value: false,
                        text: 'This and following lessons',
                      },
                      {
                        id: 'all_lessons',
                        value: false,
                        text: 'All lessons',
                      },
                    ];
                  })(),
                },
                array: {
                  remove: (field, index) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.cloneDeep(_.castArray(fieldValue));
                    fieldArray.splice(index, 1);
                    const newValue = [...fieldArray];
                    form.setFieldValue(field, newValue);
                  },
                },
              });
              return form;
            }),

            allColumns: hook((ctx) => {
              const columns = [
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.number_order')}
                    </div>
                  ),
                  render: (text, record, index) => {
                    return <div>{index + 1}</div>;
                  },
                  width: 50,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.teacher')}
                    </div>
                  ),
                  render: (val) => {
                    return (
                      <div className="identify-container">
                        <div className="table-cell align-top">
                          <Avatar size={40} src={MemberFormatter.avatarUrl(ctx)(val)} />
                        </div>

                        <div style={{ verticalAlign: 'middle' }} className="table-cell align-middle pl-5">
                          <div className="flex flex-col">
                            <div className="text-xs">{MemberFormatter.fullName(ctx)(val)}</div>
                            <div className="text-xs"> Type: {_.get(val, 'role', '')}</div>
                          </div>
                        </div>
                      </div>
                    );
                  },
                  require: true,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.email')}
                    </div>
                  ),
                  render: (val) => {
                    return <div className="text-xs">{MemberFormatter.email(ctx)(val)}</div>;
                  },
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.actions')}
                    </div>
                  ),
                  render: (text, record, index) => {
                    return (
                      <div className="text-center">
                        <div
                          className="inline-block cursor-pointer"
                          onClick={() => ctx.apply('form.array.remove', 'teachers', index)}
                        >
                          {gstyles.icons({
                            name: 'remove-circle',
                            fill: gstyles.colors.red500,
                            size: 24,
                          })}
                        </div>
                      </div>
                    );
                  },
                },
              ];
              return columns;
            }),

            tableName: hook(() => 'tblCourseRoomTeacher'),

            tableColumn: hook((ctx) => {
              const data = ctx.get('form.values.teachers');
              const tableName = ctx.get('tableName', 'tblCourseRoomTeacher');
              const [tableSettings, $tableSettings] = useLocalStorage(`@UZ::TableSettings::${tableName}`, {
                columns: [],
              });
              const ref = React.useRef({});

              const allColumns = ctx.get('allColumns');

              const columns = _.filter(ctx.get('allColumns'), (col) => {
                const key = _.get(col, 'key');
                if (_.get(tableSettings, 'columns.length')) {
                  return _.get(col, 'required') || _.includes(tableSettings.columns, key);
                }
                return _.get(col, 'defaultSelected', true);
              });
              const selectedColumns = _.map(columns, 'key');
              _.assign(ref.current, { tableSettings, $tableSettings });

              return {
                dataSource: data,
                columns,
                allColumns,
                selectedColumns,
                selectColumns: (values) => {
                  ref.current.$tableSettings({ ...ref.current.tableSettings, columns: values });
                },
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
