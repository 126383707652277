import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import moment from 'moment';
import { momentLocalizer } from 'react-big-calendar';
// import 'moment/locale/vi';
import 'moment/locale/de';
import _ from 'lodash';
import cx from 'classnames';
import AvatarGroup from '@uz/unitz-components-web/CalendarComponent/AvatarGroup';
import { TIME_HOUR_MINUTE_FORMAT } from '@vl/mod-utils/datetime';

const localizer = momentLocalizer(moment);

const timeStep = 15;
const timeSlot = 4;

export const CustomWeekEvent = ({ event }) => {
  const textColor = cx('text-white', {
    'text-brandb500': moment().isAfter(event.end),
    'text-yellow500': moment().isBetween(event.start, event.end),
    'text-blue500': moment().isBefore(event.start),
  });
  // consider numberSlot equal number line
  let numberSlot = (event.end - event.start) / (timeStep * 60 * 1000);
  const timeDuration = `${localizer.format(event.start, TIME_HOUR_MINUTE_FORMAT)} - ${localizer.format(
    event.end,
    TIME_HOUR_MINUTE_FORMAT
  )}`;
  const displayTime = numberSlot >= 4;
  const displayAvatar = numberSlot >= 3;
  if (displayTime) numberSlot -= 1;
  if (displayAvatar) numberSlot -= 1;
  const titleLine = _.ceil(numberSlot);
  const titleEllipsis = `text-ellipsis-${titleLine}`;

  return (
    <DIV>
      <div
        className={cx('flex justify-between h-full', textColor, {
          'flex-col': displayTime || displayAvatar,
        })}
      >
        {displayTime && <div className="mt-1 text-xs text-main font-normal text-ellipsis-1">{timeDuration}</div>}
        <div className={cx('font-semibold text-main text-xs', titleEllipsis)}>{event.title}</div>
        {/* {displayAvatar && (
          <div className="my-1 text-main text-xs font-normal text-left text-ellipsis-1">
            <AvatarGroup event={event} />
          </div>
        )} */}
      </div>
    </DIV>
  );
};

export default CustomWeekEvent;
