import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import { Modal, Alert, Row, Col, Input } from 'antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import '../modal.css';
import { Form, FormItem, DatePicker } from 'formik-antd';
import moment from 'moment';
import Button from '@uz/unitz-components-web/Button';
import CustomInput from '@uz/unitz-components-web/CustomInput';
import InputText from '@uz/unitz-components-web/InputText';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  labelAlign: 'left',
};

const SESSION_START_TIME_FORMAT = 'HH:mm';

export default function CourseRoomRescheduleModal() {
  return (
    <DIV className="component">
      <Modal
        title={
          <div className="py-2 text-xl text-main font-semibold">
            {ctx.apply('i18n.t', 'ZoomToolCalendar.roomActions.reschedule')}
          </div>
        }
        style={{ zIndex: !!ctx.get('form.isModalVisible') ? 1035 : 0 }}
        wrapClassName="lesson-modal"
        visible={ctx.get('form.isModalVisible')}
        destroyOnClose
        footer={null}
        width={688}
        closeIcon={<div className="mt-4">{gstyles.icons({ name: 'close', size: 24, fill: gstyles.colors.sub })}</div>}
        maskStyle={{ background: gstyles.colors.black300 }}
        onCancel={ctx.get('form.handleCancel')}
        afterClose={() => ctx.apply('modalModel.hide')}
      >
        <>
          {ctx.get('form.isModalVisible') &&
            (() => {
              const dateFormatter = (value) => {
                const TIME_FORMAT = 'HH:mm';
                const DATE_FORMAT = 'YYYY/MM/DD';
                const session_duration = ctx.get('form.values.session_duration');
                const endValue = value.clone().add(session_duration, 'second');
                return `${value.format(`${DATE_FORMAT} ${TIME_FORMAT}`)} - ${endValue.format(TIME_FORMAT)}`;
              };
              const disabledDate = (date) => {
                return !date || date.isBefore(moment(), 'days');
              };
              const disabledTime = (date) => {
                return !date || date.isBefore(moment(), 'hours');
              };

              return (
                <FormProvider form={ctx.get('form')}>
                  <Form className="space-y-4" {...layout}>
                    {ctx.get('form.status.error') && (
                      <div className="py-2">
                        <Alert message={ctx.get('form.status.error.message')} type="error" />
                      </div>
                    )}
                    {!!ctx.get('form.errors.sessions') &&
                      (_.isString(ctx.get('form.errors.sessions')) ||
                        React.isValidElement(ctx.get('form.errors.sessions'))) && (
                        <div className="py-2">
                          <Alert message={ctx.get('form.errors.sessions')} type="error" showIcon />
                        </div>
                      )}

                    <CustomInput>
                      <FormItem
                        name={'lesson_name'}
                        label={
                          <span className="text-sm font-semibold text-sub">
                            {ctx.apply('i18n.t', 'ZoomToolCalendar.roomActions.modals.form.lessonName')}
                          </span>
                        }
                      >
                        <InputText name={'lesson_name'} disabled />
                      </FormItem>
                    </CustomInput>

                    <CustomInput>
                      <FormItem
                        name={'course_name'}
                        label={
                          <span className="text-sm font-semibold text-sub">
                            {ctx.apply('i18n.t', 'ZoomToolCalendar.roomActions.modals.form.lessonName')}
                          </span>
                        }
                      >
                        <InputText name={'course_name'} disabled />
                      </FormItem>
                    </CustomInput>

                    <CustomInput>
                      <FormItem
                        name={'old_start_at'}
                        label={
                          <span className="text-sm font-semibold text-sub">
                            {ctx.apply('i18n.t', 'CourseRoom.modals.reschedule.currentTimeFrameLabel')}
                          </span>
                        }
                      >
                        <DatePicker
                          size="medium"
                          showTime={{
                            hourStep: 1,
                            minuteStep: 15,
                            format: SESSION_START_TIME_FORMAT,
                          }}
                          suffixIcon={gstyles.icons({
                            name: 'calendar',
                            size: 16,
                            fill: gstyles.colors.sub,
                          })}
                          format={dateFormatter}
                          name={'old_start_at'}
                          className="w-full"
                          disabled
                        />
                      </FormItem>
                    </CustomInput>
                    <CustomInput>
                      <FormItem
                        name={'start_at'}
                        label={
                          <span className="text-sm font-semibold text-sub">
                            {ctx.apply('i18n.t', 'CourseRoom.modals.reschedule.newTimeFrameLabel')}
                          </span>
                        }
                      >
                        <DatePicker
                          allowClear={false}
                          size="medium"
                          showTime={{
                            hourStep: 1,
                            minuteStep: 15,
                            format: SESSION_START_TIME_FORMAT,
                          }}
                          suffixIcon={gstyles.icons({
                            name: 'calendar',
                            size: 16,
                            fill: gstyles.colors.sub,
                          })}
                          format={dateFormatter}
                          disabledDate={disabledDate}
                          disabledTime={disabledTime}
                          name={'start_at'}
                          className="w-full"
                        />
                      </FormItem>
                    </CustomInput>
                    <CustomInput>
                      <FormItem
                        name={'reason'}
                        label={
                          <span className="text-sm font-semibold text-sub">
                            {ctx.apply('i18n.t', 'ZoomToolCalendar.roomActions.modals.form.reason')}
                          </span>
                        }
                      >
                        <Input.TextArea rows={3} name={'reason'} />
                      </FormItem>
                    </CustomInput>
                    <Row className="mt-6" justify="end">
                      <Col span={12}>
                        <Button type="primary" block>
                          {ctx.apply('i18n.t', 'ZoomToolCalendar.roomActions.buttons.confirmReschedule')}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </FormProvider>
              );
            })()}
        </>
      </Modal>
    </DIV>
  );
}
